@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,600;1,100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/** Custom Components */
/*@import "./components/card/pricing-card/pricing-card.styles";*/
/*@import "./components/nav/nav.styles";*/

@layer base {
    :root {
        --color-primary:    245 112 89;
        --color-secondary:  225 225 225;
        --color-success:    25 135 84;
        --color-danger:     220 53 69;
        --color-warning:    255 193 7;
        --color-info:       242 242 242;
        --color-light:      255 255 255;
        --color-dark:       0 0 0;
    }
}

@layer components {

    body{
        @apply bg-light subpixel-antialiased;
    }

    .form-group{
    @apply mb-4 text-left;
  }

  .form-label{
    @apply block text-dark text-sm font-bold mb-2 uppercase;
  }

  .form-group input , .form-group select , .form-group textarea {
    @apply w-full rounded-lg shadow;
  }


    /** pricing card */

    .p-card{
        @apply mx-auto bg-light border border-black/10 rounded-lg last:rounded-r-lg;
        @apply md:rounded-none md:first:rounded-l-lg;
        @apply my-4 md:my-0 max-w-sm;
        @apply odd:border-r-0 even:border-r-0;
    }

    .p-card-wrapper {
        @apply p-8 relative overflow-hidden flex flex-col h-full first:rounded-l-lg last:rounded-r-lg;
        @apply md:p-5 lg:p-8;
    }

    .p-card > .p-card-wrapper .header {
        @apply my-auto max-w-sm;
    }

    .p-card .header .title {
        @apply text-3xl border-b-2 border-primary font-semibold;
    }

    .p-card .header .description {
        @apply font-thin line-clamp-3;
    }

    .p-card .price {
        @apply text-2xl;
    }

    .p-card .body {
        @apply text-sm font-normal text-left;
        @apply pt-4;
    }

    .p-card .body li {
        @apply odd:bg-secondary before:odd:bg-secondary before:even:bg-light
        before:-z-0 before:flex before:absolute before:h-1/6 before:w-full before:left-0;
        @apply h-10 flex;
    }

    .p-card .price span {
        @apply text-sm font-normal text-dark;
    }

    .p-card .footer-wrapper {
        @apply before:flex before:absolute before:bg-light
        before:w-full before:h-full before:left-0 before:-z-0 basis-full flex flex-col;
    }

    .p-card .footer {
        @apply w-full flex flex-col relative z-10 mt-auto pt-4;
    }

    .p-card .icon {
        @apply inline-block bg-no-repeat h-auto w-4 justify-self-center basis-5;
        background-image: url("./components/card/pricing-card/check-solid.svg");
    }

    .featured {
        @apply relative before:block before:absolute before:bg-light before:w-full before:h-full
        before:rounded-lg before:border before:border-dark/20 before:scale-y-105 before:shadow-lg;
    }


    /**
        nav component
     */

    nav{
        @apply py-8 pl-4 pr-4 font-semibold;
        font-size: 80%;
    }

    nav ul{
        @apply flex flex-wrap justify-center items-center gap-8;
    }

    nav li:first-child{
        @apply md:basis-auto md:mr-auto md:text-left basis-full block text-center;
    }

    nav li:not(:first-child){
        @apply capitalize;
        font-size: 1rem;
    }

    nav .btn-link{
        @apply p-3.5 rounded-lg border-2 border-primary mx-2;
    }

    .btn{
        @apply p-3.5 rounded-lg mx-1.5;
    }

    .btn-primary{
        @apply bg-primary text-light border border-primary;
        @apply hover:bg-primary/75 hover:border-primary/0;
    }

    .btn-light {
        @apply bg-light text-primary border border-primary;
        @apply hover:bg-primary hover:text-light hover:border-primary/0;
    }

    .head-banner {
        /*background: url("../assets/hero_img.png");*/
        content: url("./assets/hero_img.png");
    }

    .head-background {
        background-color: #F0F0F0;
    }

    .form-group input, .form-group select, .form-group textarea {
        width: 100%;
        --tw-shadow: 0;
        --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        border-radius: .5rem;
    }

    .form-group input:focus, .form-group select:focus, .form-group textarea:focus {
        @apply border-primary;
    }

}
